import { Box, Flex, FlexProps, Grid } from '@chakra-ui/core'
import { rem } from 'design'
import React from 'react'

export type IHeroSplit = { Image: JSX.Element } & FlexProps

/**
 * Renders a hero with an image and text area, where the image shrinks
 * for mobile displays then is rendered above.
 */
const HeroSplit = ({ Image, children, ...props }: IHeroSplit) => {
  return (
    <Grid
      height="100%"
      margin="auto"
      templateColumns={{
        base: '1fr',
        tablet: '0.8fr 1fr',
        desktop: 'repeat(2, 1fr)',
      }}
      width="100%"
      {...props}
    >
      <Box>{Image}</Box>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        margin="auto"
        px={rem(24)}
        zIndex={1}
      >
        {children}
      </Flex>
    </Grid>
  )
}

export default HeroSplit
