import { Box, BoxProps } from '@butcherbox/freezer'
import BackgroundImage, { IBackgroundImageProps } from 'gatsby-background-image'
import React from 'react'
import * as Styles from '~/components/ModuleText/ModuleText.css'

interface ModuleTextProps extends BoxProps {
  imgLeft?: IBackgroundImageProps['fluid']
  imgRight?: IBackgroundImageProps['fluid']
}

export default function ModuleText({
  component = 'section',
  children,
  'aria-labelledby': ariaLabelledBy,
  imgLeft,
  imgRight,
  ...props
}: ModuleTextProps) {
  return (
    <Box
      aria-labelledby={ariaLabelledBy}
      className={Styles.ModuleText}
      component={component}
      {...props}
    >
      {imgLeft ? (
        <Box className={Styles.ImageWrap}>
          <BackgroundImage
            className={Styles.BackgroundImage}
            critical
            fadeIn={false}
            fluid={imgLeft}
          />
        </Box>
      ) : null}
      <Box className={Styles.ContentWrap}>{children}</Box>
      {imgRight ? (
        <Box className={Styles.ImageWrap}>
          <BackgroundImage
            className={Styles.BackgroundImage}
            critical
            fadeIn={false}
            fluid={imgRight}
          />
        </Box>
      ) : null}
    </Box>
  )
}
