import { IconNotes, rem } from '@butcherbox/freezer'
import { Router, useParams } from '@reach/router'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import ShadowBox from 'design/components/ShadowBox/ShadowBox'
import { SocialCardProps } from 'design/components/SocialCard/SocialCard.types'
import { Body, Subhead } from 'design/components/Typography/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import ModuleMemberPerks from '~/components/ModuleMemberPerks/ModuleMemberPerks'
import ModuleSocialCards from '~/components/ModuleSocialCards/ModuleSocialCards'
import ModuleText from '~/components/ModuleText/ModuleText'
import useStoreOfferInfo from '~/hooks/useStoreOfferInfo'
import { useWordpressResubOfferPost } from '~/hooks/useWordpressResubOfferPost'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { device } from '~/styles/device'
import { getCookieDomain } from '~/utils/cookie'
import { RouterPage } from '~/utils/route'
import { cleanJsonStrings } from '~/utils/unicode'
import { RESUBSCRIBE_COOKIE } from '../AccountCancelled/AccountCancelled'
import LoginFormSection from './LoginFormSection/LoginFormSection'

const LOGIN_SECTION_HEIGHT = 576

export default function ResubscribeRouter({ withLayout = true }) {
  const ResubscribeComponent = withLayout ? ResubscribeLayout : Resubscribe
  return (
    <Router>
      <RouterPage
        path="/resubscribe/:wordpressPostId"
        renderRoute={ResubscribeComponent}
      />
      <RouterPage default renderRoute={ResubscribeComponent} />
    </Router>
  )
}

const ResubscribeLayout = () => (
  <UnauthenticatedLayout simpleHeader title="Resubscribe">
    <Resubscribe />
  </UnauthenticatedLayout>
)

const Resubscribe = () => {
  const queryResult = cleanJsonStrings(useStaticQuery(query))
  const params = useParams() as { wordpressPostId?: string }
  const [mounted, setMounted] = React.useState(false)
  const { data: resubscribeSettings, isLoading } = useWordpressResubOfferPost(
    params?.wordpressPostId
  )

  const socialCards: SocialCardProps[] = queryResult.socialCards.edges.map(
    ({ node: card }) => ({
      name: card.name,
      avatar: card.avatar,
      image: card.image,
      text: card.text,
      id: card.id,
    })
  )

  React.useEffect(() => setMounted(true), [])
  //We need to clear any exisitng cookies to avoid overwriting localstorage
  //when user lands on AccountCancelled page from wordpress resubcribe lander
  Cookies.remove(RESUBSCRIBE_COOKIE, {
    domain: getCookieDomain(),
  })
  useStoreOfferInfo({
    wordPressPostId: !isLoading && params?.wordpressPostId,
    expired: resubscribeSettings?.expired,
  })

  return !mounted || (params?.wordpressPostId && isLoading) ? (
    <LoadingSpinner size={'large'} />
  ) : (
    <>
      <LoginFormSection
        bg="bb.ivory"
        heading="h1"
        height={{ base: 'auto', tablet: rem(LOGIN_SECTION_HEIGHT) }}
        id="resubscribe-first-login"
        img={
          resubscribeSettings?.desktopHeroImageTop
            ? [
                {
                  media: device.belowTablet,
                  src: resubscribeSettings.mobileHeroImageTop,
                },
                {
                  media: device.tablet,
                  src: resubscribeSettings.desktopHeroImageTop,
                },
              ]
            : queryResult.loginSectionImageOne.childImageSharp.fluid
        }
        location="top"
        resubscribeSettings={resubscribeSettings}
      />

      <ModuleText
        aria-labelledby="resubscribe-welcome-back"
        imgLeft={queryResult.skipWaitlistImageLeft.childImageSharp.fluid}
        imgRight={queryResult.skipWaitlistImageRight.childImageSharp.fluid}
      >
        <IconNotes marginBottom={20} variant={'duo'} />
        <Subhead
          as="h2"
          dangerouslySetInnerHTML={{
            __html: resubscribeSettings?.welcomeBackHeader || 'Welcome back',
          }}
          id="resubscribe-welcome-back"
          pb={rem(12)}
        />
        <Body
          dangerouslySetInnerHTML={{
            __html:
              resubscribeSettings?.welcomeBackBody ||
              "Once you're part of the ButcherBox community, our door is always open. We're dedicated to making sure you and your family have food you can feel good about delivered right to your doorstep. 100% grass-fed, grass-finished beef; free-range, organic chicken; pork raised crate-free; and wild-caught seafood is waiting for you when you resume your subscription.",
          }}
        />
      </ModuleText>

      <ModuleMemberPerks
        as="section"
        bgFluid={queryResult.memberPerksImage.childImageSharp.fluid}
        id="resubscribe-member-perks"
      />

      <ShadowBox>
        {params?.wordpressPostId &&
        isLoading /* Check this again to prevent page from jumping */ ? (
          <LoadingSpinner size={'regular'} />
        ) : (
          <LoginFormSection
            bg="white"
            heading="h2"
            height={{ base: 'auto', tablet: rem(LOGIN_SECTION_HEIGHT) }}
            id="resubscribe-second-login"
            img={
              resubscribeSettings?.desktopHeroImageBottom
                ? [
                    {
                      media: device.belowTablet,
                      src: resubscribeSettings.mobileHeroImageBottom,
                    },
                    {
                      media: device.tablet,
                      src: resubscribeSettings.desktopHeroImageBottom,
                    },
                  ]
                : queryResult.loginSectionImageTwo.childImageSharp.fluid
            }
            location="bottom"
            resubscribeSettings={resubscribeSettings}
          />
        )}
      </ShadowBox>
      <ModuleSocialCards
        as="section"
        cards={socialCards}
        id="resubscribe-social-proof"
      />
    </>
  )
}

const query = graphql`
  query {
    loginSectionImageOne: file(
      relativePath: { eq: "top_down_four_cuts_cast_iron_crop_on_pan.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 720) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    loginSectionImageTwo: file(
      relativePath: { eq: "box_in_test_kitchen_with_contents.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 720) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    skipWaitlistImageLeft: file(
      relativePath: { eq: "peppercorns_crop_right.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, cropFocus: EAST) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    skipWaitlistImageRight: file(
      relativePath: { eq: "peppercorns_crop_left.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    memberPerksImage: file(
      relativePath: { eq: "steak_on_serving_board_crop_center.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1441, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    socialCards: allContentfulSocialMediaCard {
      edges {
        node {
          ...SocialCard
        }
      }
    }
  }
`
