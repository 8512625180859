import { Flex } from '@chakra-ui/core'
import { useLocation, useParams } from '@reach/router'
import { rem, styled } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import type { IHeroSplit } from 'design/components/HeroSplit/HeroSplit'
import HeroSplit from 'design/components/HeroSplit/HeroSplit'
import {
  H1,
  H2,
  HeadlineGothamSmaller,
  Tiny,
} from 'design/components/Typography/Typography'
import BackgroundImage, { IBackgroundImageProps } from 'gatsby-background-image'
import React from 'react'
import { ResubscribeSettings } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'

type ILoginFormSection = {
  heading: 'h1' | 'h2'
  location: 'top' | 'bottom'
  id: string
  resubscribeSettings?: ResubscribeSettings
  img: IBackgroundImageProps['fluid']
  // TODO: Once we can pull the imgDescription from Contentful, use it
} & Omit<IHeroSplit, 'Image' | 'imgDescription'>

const CoverBackgroundImage = styled(BackgroundImage)`
  min-height: ${rem(249)};
  height: 100%;
`

const LoginFormSection: React.FC<ILoginFormSection> = ({
  heading,
  location,
  id,
  resubscribeSettings,
  img,
  ...props
}) => {
  const { search } = useLocation()
  const params = useParams()
  const { AUTH_URL } = useSwitchToSubDomain()

  return (
    <HeroSplit
      aria-labelledby={id}
      Image={
        <CoverBackgroundImage
          critical
          fadeIn={false}
          fluid={img}
          /**
           * TODO: When this image is derived from Contentful, use the
           * asset description for alt text and make this visible to SRs
           */
          role="presentation"
        />
      }
      {...props}
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        px={{ base: rem(16), tablet: rem(46) }}
        py={rem(40)}
        w="100%"
      >
        <H1
          alignItems="center"
          as={heading}
          display="flex"
          flexDirection="column"
          fontSize={rem(20)}
          fontWeight={700}
          id={id}
          textAlign="center"
        >
          {resubscribeSettings?.expired && (
            <H2 as="span" d="block" data-cy={TEST_ID.RESUB_OFFER_EXPIRED}>
              We're sorry, this offer is no longer available.
            </H2>
          )}
          <H2
            as="span"
            d="block"
            dangerouslySetInnerHTML={{
              __html:
                (location === 'top'
                  ? resubscribeSettings?.eyebrowMessageTop
                  : resubscribeSettings?.eyebrowMessageBottom) ||
                'Resume your subscription today for',
            }}
            data-cy={TEST_ID.RESUB_EYEBROW}
            pb={rem(13)}
          />
          <HeadlineGothamSmaller
            as="span"
            color="bb.spicedCrimson"
            dangerouslySetInnerHTML={{
              __html:
                (location === 'top'
                  ? resubscribeSettings?.mainMessageTop
                  : resubscribeSettings?.mainMessageBottom) ||
                'High-quality Meat You Can Trust',
            }}
            lineHeight="taller3x"
            maxWidth={rem(500)}
          />
        </H1>

        <LargeButtonPrimary
          as="a"
          data-cy={TEST_ID.RESUB_SUBMIT}
          // @ts-ignore
          href={`${AUTH_URL}/login${
            /**
             * The search bit makes sure we're forwarding UTM
             * campaign params. If the user switches webviews
             * at some point during the login process, the GA cookie
             * tracking UTM will be lost so it's important to
             * forward the query string appropriately.
             */
            params?.wordpressPostId && !resubscribeSettings.expired
              ? `?post=${params.wordpressPostId}&${(search || '').slice(1)}`
              : search
          }`}
          maxW={{ tablet: rem(328) }}
          mt={rem(40)}
          w="100%"
        >
          {resubscribeSettings?.buttonText || 'Login'}
        </LargeButtonPrimary>

        <Tiny
          color="bb.stone"
          dangerouslySetInnerHTML={{
            __html:
              location === 'top'
                ? resubscribeSettings?.disclaimerTop
                : resubscribeSettings?.disclaimerBottom,
          }}
          maxW={rem(500)}
          mt={rem(40)}
          mx="auto"
          textAlign="center"
        />
      </Flex>
    </HeroSplit>
  )
}

export default LoginFormSection
