import React from 'react'
import * as Styles from './ShadowBox.css'
import { Box, BoxProps } from '@butcherbox/freezer'
/**
 * Creates full-page padding around the first child and elevates it with
 * a box shadow.
 *
 * (FKA: HeroCardOffset)
 */
const ShadowBox = ({
  id,
  children,
  background = 'ivory',
  component,
  ...props
}: BoxProps) => {
  return (
    <Box
      aria-labelledby={id}
      background={background}
      className={Styles.ShadowBox}
      component={component}
      px={16}
      textAlign="center"
      {...props}
    >
      <Box boxShadow="default" className={Styles.ShadowBoxContents} m="auto">
        {children}
      </Box>
    </Box>
  )
}

export default ShadowBox
